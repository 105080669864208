// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.compose {
	display: flex;
	flex-direction: column;
	height: 100%;
}
div.editor-container {
	margin-top: 1rem;
	padding: 1rem;
	background-color: #f5f5f5;
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: auto;
}

lit-code {
	--editor-bg-color:      #f5f5f5;
	--hl-color-string:      #690;
	--hl-color-function:    #004eff;
	--hl-color-number:      #dd9031;
	--hl-color-operator:    #9a6e3a;
	--hl-color-class-name:  #78c3ca;
	--hl-color-punctuation: #999;
	--hl-color-property:    #905;
	--hl-color-keyword:     #8500ff;
	--hl-color-comment:     #aaa;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profile/compose.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,YAAY;AACb;AACA;CACC,gBAAgB;CAChB,aAAa;CACb,yBAAyB;CACzB,YAAY;CACZ,cAAc;CACd,gBAAgB;AACjB;;AAEA;CACC,+BAA+B;CAC/B,4BAA4B;CAC5B,+BAA+B;CAC/B,+BAA+B;CAC/B,+BAA+B;CAC/B,+BAA+B;CAC/B,4BAA4B;CAC5B,4BAA4B;CAC5B,+BAA+B;CAC/B,4BAA4B;AAC7B","sourcesContent":["div.compose {\n\tdisplay: flex;\n\tflex-direction: column;\n\theight: 100%;\n}\ndiv.editor-container {\n\tmargin-top: 1rem;\n\tpadding: 1rem;\n\tbackground-color: #f5f5f5;\n\tflex-grow: 1;\n\tflex-shrink: 0;\n\tflex-basis: auto;\n}\n\nlit-code {\n\t--editor-bg-color:      #f5f5f5;\n\t--hl-color-string:      #690;\n\t--hl-color-function:    #004eff;\n\t--hl-color-number:      #dd9031;\n\t--hl-color-operator:    #9a6e3a;\n\t--hl-color-class-name:  #78c3ca;\n\t--hl-color-punctuation: #999;\n\t--hl-color-property:    #905;\n\t--hl-color-keyword:     #8500ff;\n\t--hl-color-comment:     #aaa;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
