// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-menu {
	margin-right: 0 !important;
}
@media screen and (min-width: 1024px) {
	#menu-expand {
		display: none;
	}
}
.toast {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1000;
}

.toast.show {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profile/navbar.css"],"names":[],"mappings":"AAAA;CACC,0BAA0B;AAC3B;AACA;CACC;EACC,aAAa;CACd;AACD;AACA;EACE,eAAe;EACf,SAAS;EACT,SAAS;EACT,2BAA2B;EAC3B,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,UAAU;EACV,wBAAwB;EACxB,aAAa;AACf;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".navbar-menu {\n\tmargin-right: 0 !important;\n}\n@media screen and (min-width: 1024px) {\n\t#menu-expand {\n\t\tdisplay: none;\n\t}\n}\n.toast {\n  position: fixed;\n  top: 20px;\n  left: 50%;\n  transform: translateX(-50%);\n  background-color: #333;\n  color: white;\n  padding: 10px 20px;\n  border-radius: 8px;\n  font-size: 16px;\n  opacity: 0;\n  transition: opacity 0.3s;\n  z-index: 1000;\n}\n\n.toast.show {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
