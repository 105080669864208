// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.message-history {
	height: 100%;
	display: flex;
 	flex-direction: column;
}
.messages-nav {
	background-color: #f5f5f5;
	display: flex;
	align-items: flex-start;
}
.messages-nav > button {
	width: min-content;
}
div.messages {
	background-color: #f5f5f5;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 0 1rem 1rem 1rem;
	max-height: calc(100% - 6.5em);
	height: calc(100% - 6.5em);
}

ul.disclose-list {
	list-style-type: disc; 
	list-style-position: inside;
}
.message-body {
	background-color: #fff;
}

.message.sent .message-header {
	background-color: #48c78e;
	color: #fff;
}

.message.received .message-header {
	background-color: #3e8ed0;
	color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profile/messaging.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,aAAa;EACZ,sBAAsB;AACxB;AACA;CACC,yBAAyB;CACzB,aAAa;CACb,uBAAuB;AACxB;AACA;CACC,kBAAkB;AACnB;AACA;CACC,yBAAyB;CACzB,aAAa;CACb,sBAAsB;CACtB,yBAAyB;CACzB,yBAAyB;CACzB,8BAA8B;CAC9B,0BAA0B;AAC3B;;AAEA;CACC,qBAAqB;CACrB,2BAA2B;AAC5B;AACA;CACC,sBAAsB;AACvB;;AAEA;CACC,yBAAyB;CACzB,WAAW;AACZ;;AAEA;CACC,yBAAyB;CACzB,WAAW;AACZ","sourcesContent":["div.message-history {\n\theight: 100%;\n\tdisplay: flex;\n \tflex-direction: column;\n}\n.messages-nav {\n\tbackground-color: #f5f5f5;\n\tdisplay: flex;\n\talign-items: flex-start;\n}\n.messages-nav > button {\n\twidth: min-content;\n}\ndiv.messages {\n\tbackground-color: #f5f5f5;\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: flex-end;\n\tpadding: 0 1rem 1rem 1rem;\n\tmax-height: calc(100% - 6.5em);\n\theight: calc(100% - 6.5em);\n}\n\nul.disclose-list {\n\tlist-style-type: disc; \n\tlist-style-position: inside;\n}\n.message-body {\n\tbackground-color: #fff;\n}\n\n.message.sent .message-header {\n\tbackground-color: #48c78e;\n\tcolor: #fff;\n}\n\n.message.received .message-header {\n\tbackground-color: #3e8ed0;\n\tcolor: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
