// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.component-group {
	height: 90vh;
}
div.console-container {
	padding: 1rem;
	background-color: #f5f5f5;
	height: 60vh;
	overflow: hidden;
}
div.compose-container {
	flex-grow: 1;
}
#left {
	min-width: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profile/index.css"],"names":[],"mappings":"AAAA;CACC,YAAY;AACb;AACA;CACC,aAAa;CACb,yBAAyB;CACzB,YAAY;CACZ,gBAAgB;AACjB;AACA;CACC,YAAY;AACb;AACA;CACC,YAAY;AACb","sourcesContent":[".component-group {\n\theight: 90vh;\n}\ndiv.console-container {\n\tpadding: 1rem;\n\tbackground-color: #f5f5f5;\n\theight: 60vh;\n\toverflow: hidden;\n}\ndiv.compose-container {\n\tflex-grow: 1;\n}\n#left {\n\tmin-width: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
