// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content {
  background-color: #fff;
  padding: 1rem;
  width: 70% !important;
  border-radius: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profile/help.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,qBAAqB;EACrB,kBAAkB;AACpB","sourcesContent":[".modal-content {\n  background-color: #fff;\n  padding: 1rem;\n  width: 70% !important;\n  border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
