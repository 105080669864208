// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.console {
	height: 100%;
}
pre.logs-viewport {
	height: 90%;
	overflow: auto;
	padding: 0;
}

pre.logs-viewport code {
	display: block;
}

span.did.prefix {
	color: #d41f0b;
}
span.did.method {
	color: #1a4b99;
}
span.did.data {
	color: #32a852;
}
span.did.fragment {
	color: #bf800b;
}

span.protocol.prefix {
	color: #0070c0;
}
span.protocol.group {
	/*original slide color: "#f1c232"*/
	color: #b97713;
}
span.protocol.version {
	color: #c00000;
}
span.protocol.function {
	color: #51a33f;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profile/console.css"],"names":[],"mappings":"AAAA;CACC,YAAY;AACb;AACA;CACC,WAAW;CACX,cAAc;CACd,UAAU;AACX;;AAEA;CACC,cAAc;AACf;;AAEA;CACC,cAAc;AACf;AACA;CACC,cAAc;AACf;AACA;CACC,cAAc;AACf;AACA;CACC,cAAc;AACf;;AAEA;CACC,cAAc;AACf;AACA;CACC,kCAAkC;CAClC,cAAc;AACf;AACA;CACC,cAAc;AACf;AACA;CACC,cAAc;AACf","sourcesContent":[".console {\n\theight: 100%;\n}\npre.logs-viewport {\n\theight: 90%;\n\toverflow: auto;\n\tpadding: 0;\n}\n\npre.logs-viewport code {\n\tdisplay: block;\n}\n\nspan.did.prefix {\n\tcolor: #d41f0b;\n}\nspan.did.method {\n\tcolor: #1a4b99;\n}\nspan.did.data {\n\tcolor: #32a852;\n}\nspan.did.fragment {\n\tcolor: #bf800b;\n}\n\nspan.protocol.prefix {\n\tcolor: #0070c0;\n}\nspan.protocol.group {\n\t/*original slide color: \"#f1c232\"*/\n\tcolor: #b97713;\n}\nspan.protocol.version {\n\tcolor: #c00000;\n}\nspan.protocol.function {\n\tcolor: #51a33f;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
